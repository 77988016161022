import { CurrencyCode } from "./store/payments/currencyCode";

export type Money = [number, CurrencyCode];

export const fmtMoney = (money: Money) => {
  const [amount, currency] = money;
  return (amount / 100).toLocaleString("en-GB", {
    style: "currency",
    currency,
  });
};

export const bustDatCache = (url: string) =>
  `${url}?${Math.ceil(Math.random() * 10000000)}`;
