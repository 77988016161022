import api from "../../api";
import { IPaymentData } from "./types";
import * as settings from "./../../settings";
import { bustDatCache } from "../../utils";

class PaymentService {
  ENDPOINT = `${settings.PAYMENTS_API_DOMAIN}${settings.ENDPOINT_PAYMENTS}`;
  async fetchPayment(id: string) {
    const res = await api.get(bustDatCache(`${this.ENDPOINT}${id}/`));
    return res.data as IPaymentData;
  }
  async replacePayment(id: string) {
    const res = await api.put(`${this.ENDPOINT}${id}/replace/`);
    return res.data as IPaymentData;
  }
}

const paymentService = new PaymentService();
export default paymentService;
