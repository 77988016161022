import { AxiosError } from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";

import api from "../../../../api";
import * as settings from "../../../../settings";
import { RootState } from "../../../../store";
import { validatePaymentDoesNotNeedReplacing } from "../../../../store/payments/actions";
import {
  IOfflinePayment,
  PaymentStatus,
} from "../../../../store/payments/types";
import Divider from "../../../common/Divider";
import PayButton from "../../Forms/PayButton/PayButton";

interface Props {
  paymentData: IOfflinePayment;
  canConfirm: boolean;
  onSuccess: (status: PaymentStatus) => void;
  onReload: () => void;
}

export default function OfflinePayment({
  paymentData,
  canConfirm,
  onSuccess,
  onReload,
}: Props) {
  const dispatch = useDispatch();

  const [processing, setProcessing] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [btnLabel, setBtnLabel] = React.useState("Confirm");
  // const dispatch = useDispatch();

  const confirmPayment = async () => {
    setProcessing(true);
    try {
      const { data }: { data: IOfflinePayment } = await api.post(
        `${settings.PAYMENTS_API_DOMAIN}${settings.ENDPOINT_OFFLINE_PAYMENTS}${paymentData.id}/confirm/`
      );
      setErrorMsg("");
      onSuccess(data.status);
    } catch (error) {
      setProcessing(false);
      handleError(error as any);
    }
  };

  const handleError = (error: AxiosError) => {
    setBtnLabel("Try Again");
    try {
      setErrorMsg((error.response?.data as any).error);
    } catch (_) {
      setErrorMsg("An unknown error occurred.");
    }
  };

  const handleButtonClick = () => {
    if (btnLabel === "Confirm") {
      dispatch(validatePaymentDoesNotNeedReplacing());
    } else {
      onReload();
    }
  };

  const paymentValidated = useSelector(
    (state: RootState) => state.payments?.validated
  );
  React.useEffect(() => {
    if (paymentValidated) {
      confirmPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentValidated]);

  return canConfirm ? (
    <>
      <Divider />
      {errorMsg ? (
        <Typography gutterBottom color="error" align="center">
          <strong>{errorMsg}</strong>
        </Typography>
      ) : (
        <Typography gutterBottom color="textSecondary" align="center">
          There is nothing to pay today. Hit confirm to continue.
        </Typography>
      )}
      <PayButton
        onClick={handleButtonClick}
        label={btnLabel}
        processing={processing}
      />
    </>
  ) : null;
}
