import { AppThunk } from "../types";
import UserService from "./service";
import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_FAIL,
  REFRESH_AUTH_TOKEN_SUCCESS,
} from "./types";

const TOKEN_REFRESH_INTERVAL = 60 * 1000;

export const getUser = (): AppThunk => async (dispatch, getState) => {
  dispatch({ type: GET_USER });
  const userService = new UserService(getState().settings.SEEKR_DOMAIN);
  try {
    const user = await userService.fetchCurrentUser();
    if (user?.jwt) {
      setTimeout(() => dispatch(refreshAuthToken()), TOKEN_REFRESH_INTERVAL);
    }
    dispatch({ type: GET_USER_SUCCESS, payload: user });
  } catch (err) {
    dispatch({ type: GET_USER_FAIL, payload: err });
  }
};

export const refreshAuthToken = (): AppThunk => async (dispatch, getState) => {
  dispatch({ type: REFRESH_AUTH_TOKEN });
  const userService = new UserService(getState().settings.SEEKR_DOMAIN);
  try {
    const user = await userService.fetchCurrentUser();
    const newToken = user?.jwt ?? undefined;
    dispatch({ type: REFRESH_AUTH_TOKEN_SUCCESS, payload: newToken });
    if (newToken) {
      setTimeout(() => dispatch(refreshAuthToken()), TOKEN_REFRESH_INTERVAL);
    }
  } catch (err) {
    dispatch({ type: REFRESH_AUTH_TOKEN_FAIL, payload: err });
    dispatch(getUser());
  }
};
