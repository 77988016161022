import React from "react";
import { Typography, Box, Button } from "@material-ui/core";

function Home() {
  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Much Better Payments
      </Typography>
      <Typography variant="body2" paragraph>
        This is the Much Better Payments system. Please check the URL you've
        been given to view the payment you're interested in. Without that,
        there's nothing we can do!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="https://www.muchbetteradventures.com/"
      >
        Find an adventure!
      </Button>
    </Box>
  );
}

export default Home;
