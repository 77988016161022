import React from "react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { ValidatorForm } from "react-material-ui-form-validator";

import BillingDetailsForm from "../../Forms/BillingDetails";
import PayButton from "../../Forms/PayButton";

import { fontFamily, textBlack } from "../../../../theme/theme";
import Divider from "../../../common/Divider";
import { PaymentIntent, StripeError } from "@stripe/stripe-js";
import { BillingDetails } from "../../../../store/user/types";
import { useDispatch, useSelector } from "react-redux";
import { validatePaymentDoesNotNeedReplacing} from "../../../../store/payments/actions";
import { getPaymentIsValidated } from "../../../../store/payments/selectors";
import { useTrackPaymentInfoEntered } from "./tracking";
import {useTrackPaymentDeclined} from "../../../../utils/track/useTrackPaymentDeclined";

const useStyles = makeStyles({
  mockMuiInput: {
    border: "1px SOLID rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    padding: "18.5px 14px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
});

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: fontFamily,
      fontSize: "14px",
      color: textBlack,
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    invalid: {
      color: red[500],
    },
  },
};

type StripeResult = { paymentIntent?: PaymentIntent; error?: StripeError };

interface Props {
  paymentIntent: PaymentIntent;
  defaultBillingDetails?: BillingDetails;
  onSuccess: (paymentIntent: PaymentIntent) => void;
}

const CheckoutForm = ({
  paymentIntent,
  defaultBillingDetails = {},
  onSuccess,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [billingDetails, setBillingDetails] = React.useState(
    defaultBillingDetails
  );

  const [processing, setProcessing] = React.useState(false);
  const [cardComplete, setCardComplete] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const handleBillingDetailsFormChange = (
    nextBillingDetails: BillingDetails
  ) => {
    setBillingDetails(nextBillingDetails);
  };
  const dispatch = useDispatch();
  const paymentDeclined = useTrackPaymentDeclined();

  const handleSubmit = (event: any) => {
    // Block native form submission.
    event.preventDefault();

    // should always get to this point. Start submitting the form.
    setProcessing(true);
    setErrorMsg("");
    dispatch(validatePaymentDoesNotNeedReplacing());
  };

  const submitStripePayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!(cardElement && paymentIntent.client_secret)) {
      const errorMessage = "An unknown error occurred";
      setErrorMsg(errorMessage);
      paymentDeclined(errorMessage);
      return;
    }
    try {
      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: billingDetails,
          },
        }
      );
      setProcessing(false);
      handleResult(result);
    } catch (error: any) {
      // Fix: type any to handle the .message access below: to be fixed
      // Any non payment error.
      // This will happen if the client secret is in the wrong format or some
      // other network error with Stripe. So should not really happen.
      setProcessing(false);
      setErrorMsg(error.message);
      paymentDeclined(error.message);
    }
  };

  const handleResult = (result: StripeResult) => {
    const { error, paymentIntent } = result;
    // Error happens if card payment is declined for any reason.
    if (error) return handleError(error);
    // If we get here, we have a successful payment.
    if (paymentIntent) return handleSuccess(paymentIntent);
  };

  const handleError = (error: StripeError) => {
    const errorMessage = error.message || "An unknown error occured"
    paymentDeclined(errorMessage);
    setErrorMsg(errorMessage);
  };

  const handleSuccess = (paymentIntent: PaymentIntent) => {
    onSuccess(paymentIntent);
  };

  const paymentValidated = useSelector(getPaymentIsValidated);
  const trackPaymentInfoEntered = useTrackPaymentInfoEntered();
  React.useEffect(() => {
    if (paymentValidated) {
      trackPaymentInfoEntered();
      submitStripePayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentValidated]);

  const payButtonDisabled = !stripe || !cardComplete;

  return (
    <ValidatorForm
      instantValidate
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <Divider />

      <Typography variant="h4" component="div" gutterBottom>
        Billing Address
      </Typography>

      <BillingDetailsForm
        disabled={processing}
        handleChange={handleBillingDetailsFormChange}
        initial={billingDetails}
      />

      <Divider />

      <Typography variant="h4" component="div" gutterBottom>
        Card Details
      </Typography>

      <div className={classes.mockMuiInput}>
        <CardElement
          onChange={(e) => setCardComplete(e.complete)}
          options={{ ...cardElementOptions, disabled: processing }}
        />
      </div>

      <Divider />

      <PayButton disabled={payButtonDisabled} processing={processing} />
      {errorMsg && (
        <Box paddingY={1}>
          <Typography align="center" color="error">
            {errorMsg}
          </Typography>
        </Box>
      )}
    </ValidatorForm>
  );
};
export default CheckoutForm;