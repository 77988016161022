import { useCallback } from "react";
import { useSelector } from "react-redux";
import {EVENT_NAME_CHECKOUT_STEP_COMPLETED} from "./constants";
import {trackAnalyticsEvent} from "./event";
import {
    getCurrentPaymentCartId,
    getPaymentPlatform,
    getPaymentRef
} from "../../store/payments/selectors";

export const useTrackCheckoutStepCompleted = (step: number) => {
    const cartId = useSelector(getCurrentPaymentCartId);
    const platform = useSelector(getPaymentPlatform);
    const ref = useSelector(getPaymentRef);

    return useCallback(() => {
        trackAnalyticsEvent(EVENT_NAME_CHECKOUT_STEP_COMPLETED, {
            step,
            checkout_id: `checkout-${cartId}`,
            order_id: cartId,
            cart_id: cartId,
            payment_method: platform,
            platform,
            payment_ref: ref,
        });
    }, [cartId, platform, ref, step]);
};
