import React from "react";

import { Box, Link, Typography } from "@material-ui/core";

import { IPaymentData } from "../../store/payments/types";
import { fmtMoney } from "../../utils";
import Breakdown from "./Breakdown";
import SummaryIcon from "./SummaryIcon";
import statusDataMap from "./statusDataMap";

export type Props = { payment: IPaymentData };

const Summary = ({ payment }: Props) => {
  const statusData = statusDataMap[payment.status];
  return (
    <Box mb={1}>
      <Typography
        variant="h3"
        component="div"
        align="center"
        gutterBottom
        color={statusData.titleColor}
      >
        {statusData.title}
      </Typography>
      <Typography variant="h2" component="div" align="center">
        {fmtMoney([payment.amount, payment.currency])}
      </Typography>
      <Typography align="center">
        {payment.status === "succeeded" ||
        payment.status === "requires_capture" ? (
          <Link color="secondary" href={payment.success_redirect_url}>
            {payment.description}
          </Link>
        ) : (
          payment.description
        )}
      </Typography>
      <SummaryIcon status={payment.status} />
      {statusData.desc && (
        <Typography align="center">{statusData.desc}</Typography>
      )}
      {payment.credit_amount > 0 && (
        <Box pt={2}>
          <Breakdown
            items={[
              [
                "Account Credit",
                [-payment.credit_amount, payment.currency],
                "success",
              ],
              [
                "Card Payment",
                [payment.amount - payment.credit_amount, payment.currency],
                "total",
              ],
            ]}
          />
        </Box>
      )}
    </Box>
  );
};

export default Summary;
