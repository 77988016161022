import React from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme/theme";
import { lightGreen } from "@material-ui/core/colors";
import { fmtMoney, Money } from "../../utils";

const useStyles = makeStyles({
  head: {
    ...theme.typography.body1,
    fontWeight: "bold",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  total: {
    ...theme.typography.body1,
    fontWeight: "bold",
    border: "none",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  error: {
    ...theme.typography.body1,
    color: theme.palette.error.main,
    border: "none",
  },
  success: {
    ...theme.typography.body1,

    color: lightGreen[800],
    border: "none",
  },
  light: {
    ...theme.typography.body1,

    color: theme.palette.grey[500],
    border: "none",
  },
  normal: {
    ...theme.typography.body1,
    border: "none",
  },
});

type Variant = "normal" | "total" | "error" | "light" | "head" | "success";

type ItemTuple = [string, Money | string, Variant?];
type ItemObj = {
  description: string;
  amount: string;
  variant: Variant;
};
type Props = {
  items: ItemTuple[];
};

const Breakdown = ({ items }: Props) => {
  const classes = useStyles();

  const itemTupleToItemObj = (tuple: ItemTuple) => ({
    description: tuple[0],
    amount: typeof tuple[1] == "string" ? tuple[1] : fmtMoney(tuple[1]),
    variant: tuple[2] || "normal",
  });

  const itemObjToTableRow = (item: ItemObj, idx: number) => (
    <TableRow key={idx}>
      <TableCell className={classes[item.variant]}>
        {item.description}
      </TableCell>
      <TableCell className={classes[item.variant]} align="right">
        {item.amount}
      </TableCell>
    </TableRow>
  );

  const itemToTableRow = (item: ItemTuple, idx: number) =>
    itemObjToTableRow(itemTupleToItemObj(item), idx);

  return (
    <TableContainer>
      <Table size="small">
        <TableBody>{items.map(itemToTableRow)}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default Breakdown;
