import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getCurrentPaymentCartId } from "../../../../store/payments/selectors";
import {
  EVENT_NAME_PAYMENT_INFO_ENTERED,
  trackAnalyticsEvent,
} from "../../../../utils/track";

export const useTrackPaymentInfoEntered = () => {
  const cartId = useSelector(getCurrentPaymentCartId);

  return useCallback(() => {
    trackAnalyticsEvent(EVENT_NAME_PAYMENT_INFO_ENTERED, {
      checkout_id: `checkout-${cartId}`,
      order_id: cartId,
      step: 1,
      cart_id: cartId,
    });
  }, [cartId]);
};
