import React, {useCallback} from "react";

import { Button, CircularProgress } from "@material-ui/core";

import { CurrencyCode } from "../../../../store/payments/currencyCode";
import { fmtMoney } from "../../../../utils";
import {useTrackPaymentFormSubmitted} from "../../../../utils/track/useTrackPaymentFormSubmitted";

export type Props = React.HTMLProps<HTMLButtonElement> & {
  amount?: number;
  currency?: CurrencyCode;
  disabled?: boolean;
  processing?: boolean;
  label?: string;
  overrideLabel?: boolean;
  paymentMode?: string;
};
export default function PayButton({
  amount,
  currency,
  disabled = false,
  processing = false,
  label = "Pay Now",
  overrideLabel = false,
  onClick,
  paymentMode
}: Props) {
  const paymentFormsubmitted = useTrackPaymentFormSubmitted();
  const clickHandler = useCallback((e: any) => {
        onClick && onClick(e)
        paymentFormsubmitted(paymentMode);
      },
      [onClick, paymentFormsubmitted, paymentMode]
  );
  return (
    <Button
      type={onClick ? "button" : "submit"}
      variant="contained"
      color="primary"
      fullWidth
      disabled={disabled || processing}
      onClick={clickHandler}
    >
      {processing ? (
        <>
          <CircularProgress size={28} color="inherit" />
        </>
      ) : !overrideLabel && amount && currency ? (
        `Pay ${fmtMoney([amount, currency])}`
      ) : (
        label
      )}
    </Button>
  );
}
