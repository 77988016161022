import api from "../../api";
import * as settings from "./../../settings";
import { ICredit } from "./types";
import { bustDatCache } from "../../utils";

class AccountStatementService {
  async fetchAccountStatement() {
    const res = await api.get(
      bustDatCache(`${settings.PAYMENTS_API_DOMAIN}${settings.ENDPOINT_ACCOUNT_STATEMENT}`)
    );
    return res.data as ICredit[];
  }
}

const accountStatementService = new AccountStatementService();
export default accountStatementService;
