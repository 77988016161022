import React from "react";
import { Box, Typography } from "@material-ui/core";

const Empty = () => (
  <Box textAlign="center">
    <Typography variant="h4" color="error" gutterBottom>
      No credits.
    </Typography>
    <Typography>Your credit history is empty.</Typography>
  </Box>
);
export default Empty;