export const SET_SETTING = "SET_SETTING";
export const SET_INITIALIZED = "SET_INITIALIZED";

export type settings =
  | "STRIPE_PUBLIC_KEY"
  | "STRIPE_API_VERSION"
  | "GR4VY_ID"
  | "GR4VY_ENVIRONMENT"
  | "CLIENT_COUNTRY"
  | "SENTRY_DSN"
  | "SENTRY_ENV"
  | "SEEKR_DOMAIN"
  | "TRACKING_SCRIPT_SRC"
  | "SEGMENT_API_KEY"
  | "POSTHOG_API_KEY"
  | "PLAUSIBLE_DOMAIN";

export interface ISettingsState {
  STRIPE_PUBLIC_KEY: string;
  STRIPE_API_VERSION: string;
  GR4VY_ID: string;
  GR4VY_ENVIRONMENT?: "production" | "sandbox";
  CLIENT_COUNTRY: string;
  SENTRY_DSN: string;
  SENTRY_ENV: string;
  SEEKR_DOMAIN: string;
  TRACKING_SCRIPT_SRC: string;
  SEGMENT_API_KEY: string;
  POSTHOG_API_KEY: string;
  PLAUSIBLE_DOMAIN: string;
  isInitialized: boolean;
}

interface setSetting {
  type: typeof SET_SETTING;
  payload: {
    key: settings;
    value: string;
  };
}

interface setInitalized {
  type: typeof SET_INITIALIZED;
}

export type SettingsActionTypes = setSetting | setInitalized;
