// Custom Events

//

// Standard ECommerce Events
// @see https://segment.com/docs/connections/spec/ecommerce/v2/#browsing

// Core Ordering

export const EVENT_NAME_PAYMENT_INFO_ENTERED = "Payment Info Entered";

export const EVENT_NAME_CHECKOUT_STEP_COMPLETED = "Checkout Step Completed";

export const EVENT_NAME_CHECKOUT_STEP_VIEWED = "Checkout Step Viewed";

export const EVENT_NAME_PAYMENT_FORM_SUBMITTED = "Payment Form Submitted";

export const EVENT_NAME_PAYMENT_ACCEPTED = "Payment Accepted";

export const EVENT_NAME_PAYMENT_DECLINED = "Payment Declined";
