import {
  BillingDetails,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  IUser,
  IUserData,
  IUserDataWithJWT,
  IUserState,
  REFRESH_AUTH_TOKEN_FAIL,
  REFRESH_AUTH_TOKEN_SUCCESS,
  UserActionTypes,
} from "./types";

const initialState: IUserState = {
  user: undefined,
  fetchingUser: true,
};

const debug_default_billing_details = (): BillingDetails | undefined =>
  document.location.host === "localhost:8080"
    ? {
        address: {
          line1: "Address line 1",
          line2: "Address line 2",
          city: "London",
          country: "GB",
          state: "London",
          postal_code: "SW1 123",
        },
      }
    : undefined;

const mergeBillingDetails = (user: IUserData): IUserData => ({
  ...user,
  default_billing_details: {
    email: user.email,
    name: user.default_billing_details?.name
      ? user.default_billing_details.name
      : `${user.first_name} ${user.last_name}`,
    address: {
      ...debug_default_billing_details()?.address,
      ...user.default_billing_details?.address,
    },
  },
});

const withoutJWT = (user: IUserDataWithJWT): IUserData => {
  const { jwt: _, ...userDataWithoutJWT } = user;
  return userDataWithoutJWT;
};

const getUserData = (user: IUserData): IUserData =>
  mergeBillingDetails(withoutJWT(user));

export function userReducer(
  state = initialState,
  action: UserActionTypes
): IUserState {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        fetchingUser: true,
        fetchingUserError: undefined,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          user_data: getUserData(action.payload),
          jwt: action.payload.jwt,
        },
        fetchingUser: false,
        fetchingUserError: undefined,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        fetchingUser: false,
        fetchingUserError: action.payload,
      };
    case REFRESH_AUTH_TOKEN_FAIL:
      return {
        user: undefined,
        fetchingUser: false,
        fetchingUserError: action.payload,
      };
    case REFRESH_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          jwt: action.payload,
        } as IUser,
        fetchingUserError: undefined,
      };
    default:
      return state;
  }
}
