import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";

import * as Sentry from "@sentry/react";

// Create an Axios instance
const api = axios.create();

// Add retry logic
axiosRetry(api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response; // Return a successful response
  },
  (error) => {
    // Capture the error
    captureException(error);
    return Promise.reject(error);
  }
);

function captureException(error: AxiosError) {
  Sentry.withScope((scope) => {
    scope.setTag("url", error.config?.url);
    scope.setExtra("url", error.config?.url);
    scope.setExtra("method", error.config?.method);
    scope.setExtra("error_code", error.code);
    scope.setExtra("error_message", error.message);
    scope.setExtra("data", error.config?.data);
    scope.setExtra("status", error.response?.status ?? "unknown");
    scope.setExtra("response", error.response?.data ?? "no response");
    scope.setExtra("headers", error.config?.headers);

    Sentry.captureException(
      new Error(
        `API Error: ${error.message} ${error.code || ""} on ${error.config
          ?.url}`
      )
    );
  });
}

export default api;
