import { AxiosError } from "axios";

import { PaymentIntent } from "@stripe/stripe-js";

import { CurrencyCode } from "./currencyCode";

export type PaymentStatus =
  | "requires_payment_method"
  | "requires_confirmation"
  | "requires_action"
  | "processing"
  | "requires_capture"
  | "canceled"
  | "succeeded"
  | "refunded"
  | "part_refunded"
  | "failed"
  | "authorization_required";

export type PaymentIntentStatus =
  | "requires_payment_method"
  | "requires_confirmation"
  | "requires_action"
  | "processing"
  | "requires_capture"
  | "canceled"
  | "succeeded";

export type PaymentPlatform =
  | "gr4vy_embed"
  | "stripe_payment_intents"
  | "offline";

export interface IOfflinePayment {
  id: string;
  status: PaymentStatus;
  amount: number;
  currency: CurrencyCode;
}
export interface IGr4vyEmbedPayment {
  intent: "capture" | "authorize";
  external_identifier: string;
  embed_token: string;
  transaction_id: string;
  currency: CurrencyCode;
  amount: number;
  user_id: string;
  gr4vy_buyer_id: string;
  status: PaymentStatus;
}
export interface IPaymentData {
  id: string;
  ref: string;
  amount: number;
  credit_amount: number;
  currency: CurrencyCode;
  description: string;
  status: PaymentStatus;
  platform: PaymentPlatform;
  data: PaymentIntent | IOfflinePayment | IGr4vyEmbedPayment;
  success_redirect_url: string;
  needs_replacing: boolean;
  metadata: {
    order: string;
    trip_cart_id?: string;
  };
}
export interface IPaymentMode {
  mode?: string;
}
export interface PaymentState {
  payment?: IPaymentData;
  fetching: boolean;
  validated?: boolean;
  navigating: boolean;
  payment_mode?: IPaymentMode;
  fetchError?: AxiosError;
}

export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const VALIDATE_PAYMENT = "VALIDATE_PAYMENT";
export const VALIDATE_PAYMENT_FAIL = "VALIDATE_PAYMENT_FAIL";
export const VALIDATE_PAYMENT_SUCCESS = "VALIDATE_PAYMENT_SUCCESS";

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAIL = "FETCH_PAYMENT_FAIL";
export const NAVIGATE_TO_PAYMENT = "NAVIGATE_TO_PAYMENT";
export const NAVIGATE_TO_PAYMENT_SUCCESS = "NAVIGATE_TO_PAYMENT_SUCCESS";

export interface IFetchPaymentAction {
  type: typeof FETCH_PAYMENT;
  payload: string;
}

export interface INavigateToPaymentAction {
  type: typeof NAVIGATE_TO_PAYMENT;
  payload: string;
}
export interface INavigateToPaymentSuccessAction {
  type: typeof NAVIGATE_TO_PAYMENT_SUCCESS;
  payload: string;
}

export interface IFetchPaymentSuccessAction {
  type: typeof FETCH_PAYMENT_SUCCESS;
  payload: IPaymentData;
}

export interface IFetchPaymentFailAction {
  type: typeof FETCH_PAYMENT_FAIL;
  payload: AxiosError;
}

export interface IValidatePaymentSuccessAction {
  type: typeof VALIDATE_PAYMENT_SUCCESS;
}

export interface IValidatePaymentFailAction {
  type: typeof VALIDATE_PAYMENT_FAIL;
}

export interface IValidatePaymentAction {
  type: typeof VALIDATE_PAYMENT;
}

export interface IUpdatePaymentAction {
  type: typeof UPDATE_PAYMENT;
  payload: {
    payment: IPaymentData;
    status: PaymentStatus;
    data: object;
  };
}

export type PaymentActionTypes =
  | IFetchPaymentAction
  | IFetchPaymentSuccessAction
  | IFetchPaymentFailAction
  | IValidatePaymentSuccessAction
  | IValidatePaymentFailAction
  | IUpdatePaymentAction
  | IValidatePaymentAction
  | INavigateToPaymentAction
  | INavigateToPaymentSuccessAction;
