import "./App.css";

import React from "react";
import { Provider } from "react-redux";

import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { LocationProvider } from "@reach/router";
import * as Sentry from "@sentry/react";

import Layout from "./Layout";
import SentryInit from "./components/SentryInit";
import TrackingInit from "./components/TrackingInit";
import UserInit from "./components/UserInit";
import store from "./store";
import { SET_INITIALIZED, SET_SETTING, settings } from "./store/settings/types";
import theme from "./theme/theme";

const dataSetSettings: Record<string, settings> = {
  stripePublicKey: "STRIPE_PUBLIC_KEY",
  stripeApiVersion: "STRIPE_API_VERSION",
  gr4vyId: "GR4VY_ID",
  gr4vyEnvironment: "GR4VY_ENVIRONMENT",
  clientCountry: "CLIENT_COUNTRY",
  sentryDsn: "SENTRY_DSN",
  sentryEnv: "SENTRY_ENV",
  seekrDomain: "SEEKR_DOMAIN",
  trackingScriptSrc: "TRACKING_SCRIPT_SRC",
  segmentApiKey: "SEGMENT_API_KEY",
  posthogApiKey: "POSTHOG_API_KEY",
  plausibleDomain: "PLAUSIBLE_DOMAIN",
};

const App = ({ dataSet }: { dataSet?: DOMStringMap }) => {
  React.useEffect(() => {
    if (dataSet) {
      Object.entries(dataSetSettings).forEach(([key, value]) => {
        if (dataSet[key] !== undefined) {
          store.dispatch({
            type: SET_SETTING,
            payload: { key: value, value: dataSet[key] as string },
          });
        }
      });
      store.dispatch({ type: SET_INITIALIZED });
    }
  }, [dataSet]);

  return (
    <Provider store={store}>
      <UserInit />
      <SentryInit />
      <TrackingInit />
      <CssBaseline />
      <LocationProvider>
        <ThemeProvider theme={theme}>
          <Layout />
        </ThemeProvider>
      </LocationProvider>
    </Provider>
  );
};

export default Sentry.withProfiler(App);
