import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const NeedsReplacingDialog = () => (
  <Dialog open>
    <DialogTitle disableTypography>
      <Typography variant="h4">Account Credit Updated!</Typography>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Your available credit balance has changed since this payment was
        created. Please go back and try again.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button href="/account-statement" variant="text" color="secondary">
        View Credit Account
      </Button>
      <Button
        onClick={() => window.history.back()}
        variant="contained"
        color="primary"
      >
        Go Back
      </Button>
    </DialogActions>
  </Dialog>
);
export default NeedsReplacingDialog;