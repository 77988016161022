import React from "react";
import { useSelector } from "react-redux";

import * as Sentry from "@sentry/react";

import { RootState } from "../store";

const SentryInit: React.FC = () => {
  const isInitialized = useSelector(
    (state: RootState) => state.settings.isInitialized
  );
  const sentryDsn = useSelector(
    (state: RootState) => state.settings.SENTRY_DSN
  );
  const sentryEnv = useSelector(
    (state: RootState) => state.settings.SENTRY_ENV
  );
  React.useEffect(() => {
    if (isInitialized && sentryDsn) {
      Sentry.init({
        dsn: sentryDsn,
        environment: sentryEnv,
        integrations: [Sentry.browserTracingIntegration()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: sentryEnv === "production" ? 0.1 : 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [], // DISABLED to prevent tracing of all requests
        // ["localhost"] + sentryEnv === "production"
        //   ? ["pay.muchbetteradventures.com", "www.muchbetteradventures.com"]
        //   : ["pay.mba-staging.com", "www.mba-staging.com"],
      });
    }
  }, [sentryDsn, sentryEnv, isInitialized]);
  return null;
};

export default SentryInit;
