import {
    AccountStatementState,
    AccountStatementActionTypes,
    GET_ACCOUNTSTATEMENT,
    GET_ACCOUNTSTATEMENT_SUCCESS,
    GET_ACCOUNTSTATEMENT_FAIL,
} from "./types";

const initialState: AccountStatementState = {
    fetching: false,
};

export function accountStatementReducer(
    state = initialState,
    action: AccountStatementActionTypes
): AccountStatementState {
    switch (action.type) {
        case GET_ACCOUNTSTATEMENT:
            return {
                ...state,
                fetching: true,
            };
        case GET_ACCOUNTSTATEMENT_SUCCESS:
            return {
                credits: action.payload,
                fetching: false,
            };
        case GET_ACCOUNTSTATEMENT_FAIL:
            return {
                ...state,
                fetching: false,
            };
        default:
            return state;
    }
}
