import React from "react";
import { Box, Avatar } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { PaymentStatus } from "../../store/payments/types";
import statusDataMap from "./statusDataMap";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconAvatarContainer: {
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "space-around",
    },
    iconAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      fontSize: 32,
    },
  })
);

export type Props = { status: PaymentStatus };

const SummaryIcon = ({ status }: Props) => {
  const classes = useStyles();
  const statusData = statusDataMap[status];
  return statusData.icon ? (
    <Box className={classes.iconAvatarContainer}>
      <Avatar
        className={classes.iconAvatar}
        style={{ backgroundColor: statusData.iconColor }}
      >
        <statusData.icon fontSize="inherit" />
      </Avatar>
    </Box>
  ) : null;
};

export default SummaryIcon;
