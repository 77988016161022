import {
  ISettingsState,
  SET_INITIALIZED,
  SET_SETTING,
  SettingsActionTypes,
} from "./types";

const initialState: ISettingsState = {
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
  STRIPE_API_VERSION: "2020-03-02",
  GR4VY_ID: process.env.REACT_APP_GR4VY_ID || "",
  GR4VY_ENVIRONMENT:
    (process.env.REACT_APP_GR4VY_ENVIRONMENT as "sandbox" | "production") ||
    undefined,
  CLIENT_COUNTRY: "GB",
  SENTRY_DSN: "",
  SENTRY_ENV: "dev",
  SEEKR_DOMAIN: "",
  TRACKING_SCRIPT_SRC: "",
  SEGMENT_API_KEY: "",
  POSTHOG_API_KEY: "",
  PLAUSIBLE_DOMAIN: "",
  isInitialized: false,
};

export function settingsReducer(
  state = initialState,
  action: SettingsActionTypes
): ISettingsState {
  switch (action.type) {
    case SET_SETTING:
      return action.payload.value.startsWith("{{") // starts with the template - e.g. not set
        ? { ...state }
        : { ...state, [action.payload.key]: action.payload.value };
    case SET_INITIALIZED:
      return { ...state, isInitialized: true };
    default:
      return state;
  }
}
