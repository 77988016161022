export const ENDPOINT_CURRENT_USER =
  process.env.REACT_APP_ENDPOINT_CURRENT_USER ||
  "/api/v3/accounts/user/logged_in/";

export const PAYMENTS_API_DOMAIN =
  process.env.REACT_APP_PAYMENTS_API_DOMAIN || "";

export const ENDPOINT_PAYMENTS =
  process.env.REACT_APP_ENDPOINT_PAYMENTS || "/api/v1/payments/";

export const ENDPOINT_OFFLINE_PAYMENTS =
  process.env.REACT_APP_ENDPOINT_OFFLINE_PAYMENTS ||
  "/api/v1/payments/offline-payment/";

export const ENDPOINT_GR4VY_EMBED_PAYMENTS =
  process.env.REACT_APP_ENDPOINT_GR4VY_EMBED_PAYMENTS ||
  "/api/v1/payments/gr4vy-embed/";

export const ENDPOINT_ACCOUNT_STATEMENT =
  process.env.REACT_APP_ENDPOINT_ACCOUNT_STATEMENT ||
  "/api/v1/credits/account-statement/";
