import { TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { format } from "fecha";
import React from "react";

import { ICredit } from "../../store/accountStatement/types";
import theme from "../../theme/theme";
import { fmtMoney } from "../../utils";

const useStyles = makeStyles({
  debit: {
    ...theme.typography.body1,

    color: theme.palette.grey[500],
    border: "none",
    paddingTop: 0,
    paddingBottom: 4,
  },
  credit: {
    ...theme.typography.body1,

    border: "none",
    paddingBottom: 4,
  },
  right: {
    textAlign: "right",
  },
  nowrap: {
    whiteSpace: "nowrap",
  },
});

export interface Props {
  credit: ICredit;
}

const dateFormat = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, "mediumDate");
};

const Credit = ({ credit }: Props) => {
  const classes = useStyles();

  return (
    <>
      <TableRow key={credit.id}>
        <TableCell className={classes.credit}>{credit.description}</TableCell>
        <TableCell className={clsx(classes.credit, classes.nowrap)}>
          {dateFormat(credit.expiry_date)}
        </TableCell>
        <TableCell className={clsx(classes.credit, classes.right)}>
          {fmtMoney([credit.amount, "gbp"])}
        </TableCell>
        <TableCell className={clsx(classes.credit, classes.right)}>
          {fmtMoney([credit.balance, "gbp"])}
        </TableCell>
      </TableRow>

      {credit.allocations.map((debit) => (
        <TableRow key={debit.debit_id}>
          <TableCell className={classes.debit}>{debit.description}</TableCell>
          <TableCell className={clsx(classes.debit, classes.nowrap)}>
            {dateFormat(debit.timestamp)}
          </TableCell>
          <TableCell className={clsx(classes.debit, classes.right)}>
            {fmtMoney([-debit.amount, "gbp"])}
          </TableCell>
          <TableCell className={clsx(classes.debit, classes.right)}>
            &nbsp;
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
export default Credit;
