import React from "react";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { RootState } from "../../store";
import { fetchPayment } from "../../store/payments/actions";
import { PaymentStatus } from "../../store/payments/types";
import { useTrackCheckoutStepCompleted } from "../../utils/track/useTrackCheckoutStepCompleted";
import { useTrackCheckoutStepViewed } from "../../utils/track/useTrackCheckoutStepViewed";
import { useTrackPaymentAccepted } from "../../utils/track/useTrackPaymentAccepted";
import Loading from "./Loading";
import NotFound from "./NotFound";
import Payment from "./Payment";

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & { paymentId: string };

const PaymentHOC = ({
  payment,
  fetching,
  fetchError,
  navigating,
  paymentId,
  user,
}: Props) => {
  const dispatch = useDispatch();

  const paymentAccepted = useTrackPaymentAccepted();
  const checkoutStepViewed = useTrackCheckoutStepViewed(2);
  const checkoutStepCompleted = useTrackCheckoutStepCompleted(2);
  React.useEffect(() => {
    dispatch(fetchPayment(paymentId));
  }, [dispatch, paymentId]);
  React.useEffect(() => {
    if (!fetching && !navigating) {
      checkoutStepViewed();
    }
  }, [checkoutStepViewed, paymentId, fetching, navigating]);

  const handleSuccess = (status: PaymentStatus) => {
    if (!payment) return;
    if (status === "succeeded" || status === "requires_capture") {
      paymentAccepted();
      checkoutStepCompleted();
      window.location.href = payment.success_redirect_url.replace(
        "http://localhost:8080",
        ""
      );
    } else {
      dispatch(fetchPayment(paymentId));
    }
  };

  const handleReload = () => {
    dispatch(fetchPayment(paymentId));
  };

  if (fetching || navigating) return <Loading />;

  if (!payment) return <NotFound fetchError={fetchError} />;
  return (
    <Payment
      payment={payment}
      user={user}
      onSuccess={handleSuccess}
      onReload={handleReload}
    />
  );
};

const mapState = ({
  payments: { payment, fetchError, fetching, navigating },
  user,
}: RootState) => ({
  payment,
  fetching,
  fetchError,
  navigating,
  user: user.user?.user_data,
});

const connector = connect(mapState);

export default connector(PaymentHOC);
