import { createTheme } from "@material-ui/core/styles";
import typography, {
  fontFamily,
  fontFamilyBold,
  fontFamilySerif,
  textBlack,
} from "./typography";

const theme = createTheme({
  palette: {
    primary: {
      main: "#98BE42",
    },
    secondary: {
      main: "#428bca",
    },
  },
  typography, // imported from typography.js
  overrides: {
    MuiCollapse: {
      entered: {
        height: "auto",
        overflow: "visible",
      },
    },
    MuiTypography: {
      body2: {
        "& p": { fontFamilySerif },
      },
      body1: {
        "& p": { fontFamily },
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily,
        fontWeight: "normal", // overide bootstrap label styles
        margin: 0,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontFamily,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily,
        zIndex: 999,
      },
    },
    MuiFormControl: {
      root: {
        fontFamily,
      },
    },
    MuiButton: {
      root: {
        fontFamily,
        fontWeight: 700,

        // borderRadius: 100,
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
      },

      containedPrimary: {
        color: "white",
      },
    },
    MuiDialogTitle: {
      root: {
        "& h6": { ...typography.h4 },
      },
    },
  },
});

export default theme;
export {
  textBlack, fontFamily, fontFamilyBold, fontFamilySerif,
};
