import { AxiosError } from "axios";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import Embed, { Gr4vyEmbedProps } from "@gr4vy/embed-react";
import { Transaction } from "@gr4vy/embed/lib/types";
import { Typography } from "@material-ui/core";

import api from "../../../../api";
import * as settings from "../../../../settings";
import { RootState } from "../../../../store";
import {
  IGr4vyEmbedPayment,
  PaymentStatus,
} from "../../../../store/payments/types";
import { BillingDetails } from "../../../../store/user/types";
import { useTrackPaymentDeclined } from "../../../../utils/track/useTrackPaymentDeclined";
import Divider from "../../../common/Divider";
import AcceptedCards from "../../AcceptedCards";
import PayButton from "../../Forms/PayButton";
import AmexWarningDialog from "./AmexWarningDialog";
import UnprocessablePaymentDialog from "./UnprocessablePaymentDialog";

interface Props {
  paymentData: IGr4vyEmbedPayment;
  onSuccess: (status: PaymentStatus) => void;
  defaultBillingDetails?: BillingDetails;
}

type PaymentStatusMap = {
  [key: string]: PaymentStatus;
};

const statusMap: PaymentStatusMap = {
  processing: "processing",
  buyer_approval_pending: "requires_action",
  authorization_succeeded: "requires_capture",
  authorization_failed: "failed",
  authorization_declined: "canceled",
  capture_pending: "processing",
  capture_succeeded: "succeeded",
  authorization_void_pending: "processing",
  authorization_voided: "canceled",
};

export default function Gr4vyEmbed({ paymentData, onSuccess }: Props) {
  // NOTE: All the useCallbacks and useMemo is so that we don't re-render the
  // Embed component which causes it to reset

  const [showAmexWarningDialog, setShowAmexWarningDialog] =
    React.useState(false);
  const [showUnprocessablePaymentDialog, setShowUnprocessablePaymentDialog] =
    React.useState(false);
  const [payButtonDisabled, setBtnDisabled] = React.useState(true);
  const [processing, setProcessing] = React.useState(false);
  const [paymentMode, setPaymentMode] = React.useState("card");
  const [errorMsg, setErrorMsg] = React.useState("");
  const [btnLabel, setBtnLabel] = React.useState(
    undefined as string | undefined
  );

  const paymentDeclined = useTrackPaymentDeclined();
  const handleError = useCallback(
    (error: AxiosError) => {
      console.log("handleError:", error);

      setBtnLabel("Try Again");
      try {
        const errorMessage =
          (error.response?.data as any).error || "An unknown error occured";
        setErrorMsg(errorMessage);
        if (error.response?.status === 422) {
          setShowUnprocessablePaymentDialog(true);
        }
        paymentDeclined(errorMessage);
      } catch (_) {
        const errorMessage = "An unknown error occurred.";
        setErrorMsg(errorMessage);
        paymentDeclined(errorMessage);
      }
    },
    [paymentDeclined]
  );

  const handleComplete = useCallback(
    async (transaction: Transaction) => {
      console.log("handleComplete:", transaction);

      try {
        await api.post(
          `${settings.PAYMENTS_API_DOMAIN}${settings.ENDPOINT_GR4VY_EMBED_PAYMENTS}handle-complete/`,
          transaction,
          {
            headers: {
              Authorization: "",
            },
          }
        );
        setProcessing(false);
        onSuccess(statusMap[transaction.status]);
      } catch (error) {
        paymentDeclined(
          ((error as AxiosError).response?.data as any).error ||
            "An unknown error occured"
        );
        setProcessing(false);
        handleError(error as any);
      }
    },
    [onSuccess, handleError, paymentDeclined]
  );

  const handleEvent = useCallback(
    (name: string, data: any) => {
      console.log("handleEvent:", name, data);
      if (name === "formUpdate") {
        setBtnDisabled(!data);
      } else if (name === "transactionFailed") {
        if (data?.paymentMethod?.scheme === "amex") {
          setShowAmexWarningDialog(true);
        }
        setProcessing(false);
        paymentDeclined("Transaction Failed", data?.paymentMethod?.scheme);
      } else if (name === "paymentMethodSelected") {
        setBtnDisabled(false);
        if (data.mode) {
          setPaymentMode(data.mode);
        }

        if (data.mode === "redirect") {
          setBtnLabel("Continue");
        } else if (data.mode === "applepay") {
          setBtnLabel("Pay with Apple Pay");
        } else if (data.mode === "googlepay") {
          setBtnLabel("Pay with Google Pay");
        } else if (data.id && data.method === undefined) {
          // Selected saved card details
          setBtnLabel("");
        } else {
          setBtnDisabled(true);
          setBtnLabel("");
        }
      } else if (name === "transactionCancelled") {
        setProcessing(false);
        setBtnDisabled(false);
        setBtnLabel("");
        paymentDeclined("Transaction Cancelled", data?.paymentMethod?.scheme);
      }
    },
    [setProcessing, setBtnDisabled, paymentDeclined]
  );

  const handleSubmit = useCallback(() => {
    setProcessing(true);
    setErrorMsg("");
  }, [setProcessing]);

  const { GR4VY_ID, GR4VY_ENVIRONMENT, CLIENT_COUNTRY } = useSelector(
    (store: RootState) => store.settings
  );

  const params: Gr4vyEmbedProps = useMemo(() => {
    return {
      gr4vyId: GR4VY_ID,
      environment: GR4VY_ENVIRONMENT,
      form: "#payment-form",
      amount: paymentData.amount,
      intent: paymentData.intent,
      currency: paymentData.currency.toUpperCase(),
      metadata: {
        intent: paymentData.intent, // pass this in as metadata so we can use it to power the payment rules
      },
      country: CLIENT_COUNTRY,
      token: paymentData.embed_token,
      buyerId: paymentData.gr4vy_buyer_id,
      externalIdentifier: paymentData.external_identifier,
      // TODO: GR4VY - what details if any do we NEED to collect here?
      billingAddressFields: {
        address: {
          houseNumberOrName: true,
          line1: true,
          city: true,
          postalCode: true,
          state: true,
          country: true,
        },
        emailAddress: true,
        firstName: true,
        lastName: true,
      },
      store: paymentData.user_id ? "ask" : false,
      showDeleteButton: true,
      onEvent: handleEvent,
      onComplete: handleComplete,
    };
  }, [
    handleComplete,
    handleEvent,
    paymentData,
    GR4VY_ID,
    GR4VY_ENVIRONMENT,
    CLIENT_COUNTRY,
  ]);

  return !Boolean(paymentData.transaction_id) &&
    paymentData.status === "requires_payment_method" ? (
    <>
      <AcceptedCards />

      <form onSubmit={handleSubmit} id="payment-form">
        <Embed {...params} />
        <Divider />
        {errorMsg ? (
          <Typography gutterBottom color="error" align="center">
            <strong>{errorMsg}</strong>
          </Typography>
        ) : null}
        <PayButton
          disabled={payButtonDisabled}
          processing={processing}
          label={btnLabel}
          overrideLabel={Boolean(btnLabel)}
          paymentMode={paymentMode}
        />
      </form>
      <AmexWarningDialog
        open={showAmexWarningDialog}
        onClose={() => setShowAmexWarningDialog(false)}
      />
      <UnprocessablePaymentDialog
        open={showUnprocessablePaymentDialog}
        onClose={() => setShowUnprocessablePaymentDialog(false)}
      />
    </>
  ) : null;
}
