import React from "react";

import { Box, Typography } from "@material-ui/core";

import { DiscoverIcon, MasterCardIcon, VisaIcon } from "../cards";

const AcceptedCards = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexWrap="wrap"
  >
    <Box flexShrink={0} p={1}>
      <Typography variant="h6">We accept the following cards</Typography>
    </Box>
    <Box flexShrink={0} p={1}>
      <VisaIcon />
      <MasterCardIcon />
      <DiscoverIcon />
    </Box>
  </Box>
);

export default AcceptedCards;
