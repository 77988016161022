import { useCallback, useEffect, useState } from "react";

export const useScript = (
  id: string,
  src: string,
  attributes: { [key: string]: string } = {}
) => {
  const [isScriptLoaded, setScriptLoadedState] = useState(false);

  const setScriptLoaded = useCallback(() => {
    setScriptLoadedState(true);
  }, []);

  useEffect(() => {
    const script = getOrInsertScriptTag(id, src, attributes);

    script.addEventListener("load", setScriptLoaded);
    return () => {
      script.removeEventListener("load", setScriptLoaded);
    };
  }, [attributes, id, setScriptLoaded, src]);

  return isScriptLoaded;
};

const getOrInsertScriptTag = (
  id: string,
  src: string,
  attributes: { [key: string]: string }
) => {
  const existingScript = document.getElementById(id);
  if (existingScript) {
    return existingScript;
  }

  const script = document.createElement("script");
  script.src = src;
  script.id = id;
  script.type = "text/javascript";
  script.async = true;

  Object.keys(attributes).forEach((attributeKey) => {
    script.setAttribute(attributeKey, attributes[attributeKey]);
  });

  document.head.append(script);
  return script;
};
