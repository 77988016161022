import * as Sentry from "@sentry/react";

import * as settings from "./../../settings";
import api from "../../api";
import { IUserDataWithJWT } from "./types";

function setJWTHeader(token: string) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function unsetJWTHeader() {
  delete api.defaults.headers.common["Authorization"];
}

class UserService {
  seekrDomain: string;

  constructor(seekrDomain: string) {
    this.seekrDomain = seekrDomain;
  }

  async fetchCurrentUser(): Promise<IUserDataWithJWT> {
    const res = await api.get(
      `${this.seekrDomain}${settings.ENDPOINT_CURRENT_USER}`,
      {
        withCredentials: true,
        headers: {
          Authorization: "",
        },
      }
    );

    if (res.data.user !== null) {
      const user = res.data.user as IUserDataWithJWT;
      if (user.jwt) {
        setJWTHeader(user.jwt);
      } else {
        unsetJWTHeader();
      }
      Sentry.setUser({ id: user.id, email: user.email });
      return user;
    }

    unsetJWTHeader();
    Sentry.setUser(null);
    return {
      email: "",
      first_name: "",
      last_name: "",
      id: 0,
      is_host: false,
      is_staff: false,
      userprofile: {},
    };
  }
}

export default UserService;
