import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "../../store";
import AccountStatement from "./AccountStatement";
import { getAccountStatement } from "../../store/accountStatement/actions";
import Loading from "./Loading";
import Empty from "./Empty";
import NotAuthorized from "./NotAuthorized";

const mapState = ({ accountStatement, user }: RootState) => ({
  ...accountStatement,
  user: user.user,
});

const connector = connect(mapState);
export type Props = ConnectedProps<typeof connector>;

const AccountStatementHOC = ({ credits, fetching, user }: Props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAccountStatement());
  }, [dispatch, user]);

  if (!user) return <NotAuthorized />;
  if (fetching) return <Loading />;
  if (!credits || credits.length === 0) return <Empty />;

  return <AccountStatement credits={credits} />;
};

export default connector(AccountStatementHOC);
