export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";
export const REFRESH_AUTH_TOKEN_SUCCESS = "REFRESH_AUTH_TOKEN_SUCCESS";
export const REFRESH_AUTH_TOKEN_FAIL = "REFRESH_AUTH_TOKEN_FAIL";

export type BillingDetails = {
  name?: string;
  email?: string;
  phone?: string;
  address?: {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
  };
};

export interface IUserState {
  user?: IUser;
  fetchingUser: boolean;
  fetchingUserError?: Error;
}
export interface IUser {
  user_data: IUserData;
  jwt?: string;
}
export interface IUserData {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  is_host: boolean;
  is_staff: boolean;
  intercom_user_hash?: string;
  userprofile: {
    [key: string]: any;
  };
  default_billing_details?: BillingDetails;
}
export interface IUserDataWithJWT extends IUserData {
  jwt?: string;
}

interface getUserAction {
  type: typeof GET_USER;
}

interface getUserSuccessAction {
  type: typeof GET_USER_SUCCESS;
  payload: IUserDataWithJWT;
}

interface getUserFailAction {
  type: typeof GET_USER_FAIL;
  payload: Error;
}

interface refreshAuthTokenAction {
  type: typeof REFRESH_AUTH_TOKEN;
}

interface refreshAuthTokenSuccessAction {
  type: typeof REFRESH_AUTH_TOKEN_SUCCESS;
  payload: string;
}

interface refreshAuthTokenFailAction {
  type: typeof REFRESH_AUTH_TOKEN_FAIL;
  payload: Error;
}

export type UserActionTypes =
  | getUserAction
  | getUserSuccessAction
  | getUserFailAction
  | refreshAuthTokenAction
  | refreshAuthTokenSuccessAction
  | refreshAuthTokenFailAction;
