import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { paymentsReducer } from "./payments/reducers";
import { userReducer } from "./user/reducers";
import { accountStatementReducer } from "./accountStatement/reducers";
import { settingsReducer } from "./settings/reducers";

const rootReducer = combineReducers({
  payments: paymentsReducer,
  accountStatement: accountStatementReducer,
  user: userReducer,
  settings: settingsReducer,
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;
