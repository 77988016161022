import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserData } from "../../store/user/selectors";

export const trackAnalyticsIdentity = (
  userId: number,
  fullName: string,
  email: string,
  intercomUserHash?: string
) => {
  try {
    if (window.analytics) {
      window.analytics.identify(
        userId,
        {
          name: fullName,
          email,
          user_id: userId,
        },
        {
          Intercom: {
            user_hash: intercomUserHash,
          },
        }
      );
    }
  } catch (err) {
    console.error("An error occured whilst trying to track identity: ", err);
  }
};

export const useTrackIdentity = (isReady: boolean) => {
  const user = useSelector(getUserData);

  useEffect(() => {
    if (isReady && user && user.id) {
      trackAnalyticsIdentity(
        user.id,
        `${user.first_name || ""} ${user.last_name || ""}`,
        user.email,
        user.intercom_user_hash
      );
    }
  }, [user, isReady]);
};
