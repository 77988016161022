import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store";
import { Tracking } from "../utils/track";

const TrackingInit: React.FC = () => {
  const isInitialized = useSelector(
    (state: RootState) => state.settings.isInitialized
  );

  return isInitialized ? <Tracking /> : null;
};

export default TrackingInit;
