import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@material-ui/core";

const AmexWarningDialog = (props: DialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent>
        <Typography>
          If you’re having trouble with your card, please check your card type
          as we only accept Visa, Mastercard and Discover.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            props.onClose && props.onClose(event, "backdropClick")
          }
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AmexWarningDialog;
