import { red, orange, blueGrey, lightBlue } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import TimerIcon from "@material-ui/icons/Timer";
import RefreshIcon from "@material-ui/icons/ReplayOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { PaymentStatus } from "../../store/payments/types";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";
import theme from "../../theme/theme";

// TODO: this will probs be required elsewhere..
type MUITypographyColor =
  | "initial"
  | "inherit"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error";

export type StatusData = {
  title: string;
  titleColor?: MUITypographyColor;
  iconColor?: string;
  desc?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
};

export type StatusDataMap = {
  [key in PaymentStatus]: StatusData;
};

const statusDataMap: StatusDataMap = {
  requires_action: {
    title: "Requires Action",
    desc: "Your card issuer is requesting authrorization in another window.",
    titleColor: "inherit",
    icon: TimerIcon,
    iconColor: blueGrey[500],
  },
  requires_capture: {
    title: "Payment Authorized",
    titleColor: "primary",
    desc: "The amount shown has been authroized and is awaiting capture.",
    icon: CheckIcon,
    iconColor: theme.palette.primary.main,
  },
  requires_confirmation: { title: "Confirm Payment" },
  requires_payment_method: { title: "Payment Due" },
  canceled: {
    title: "Cancelled",
    desc: "The payment has been cancelled.",
    titleColor: "error",
    icon: CloseIcon,
    iconColor: red[500],
  },
  failed: {
    title: "Failed",
    titleColor: "error",
    desc: "The payment attempt failed.",
    icon: CloseIcon,
    iconColor: red[500],
  },
  processing: {
    title: "Processing...",
    titleColor: "textSecondary",
    desc: "We are processing your payment, please wait.",
    icon: TimerIcon,
    iconColor: orange[500],
  },
  succeeded: {
    title: "Paid",
    titleColor: "primary",
    desc: "The full payment has been taken.",
    icon: CheckIcon,
    iconColor: theme.palette.primary.main,
  },
  refunded: {
    title: "Refunded",
    titleColor: "inherit",
    desc: "The full payment amount has been refunded.",
    icon: RefreshIcon,
    iconColor: lightBlue[500],
  },
  part_refunded: {
    title: "Part Refunded",
    titleColor: "inherit",
    desc: "Part of the payment amount has been refunded.",
    icon: RefreshIcon,
    iconColor: orange[500],
  },
  authorization_required: {
    title: "Authorization Required",
    titleColor: "inherit",
    desc: "We need you to authorize the payment.",
    icon: ErrorIcon,
    iconColor: lightBlue[500],
  },
};

export default statusDataMap;
