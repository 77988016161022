import { memo } from "react";

import { useSendEarlyAnalyticsTrackingEvents } from "./event";
import { useTrackIdentity } from "./identify";
import { useLoadTracking } from "./useLoadTracking";

const Tracking = () => {
  const isTrackingLoaded = useLoadTracking();
  useTrackIdentity(isTrackingLoaded);
  useSendEarlyAnalyticsTrackingEvents(isTrackingLoaded);

  return null;
};

export default memo(Tracking);
