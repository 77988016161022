import { PaymentIntent } from "@stripe/stripe-js";

import {
  FETCH_PAYMENT,
  FETCH_PAYMENT_FAIL,
  FETCH_PAYMENT_SUCCESS,
  IOfflinePayment,
  NAVIGATE_TO_PAYMENT,
  NAVIGATE_TO_PAYMENT_SUCCESS,
  PaymentActionTypes,
  PaymentState,
  UPDATE_PAYMENT,
  VALIDATE_PAYMENT,
  VALIDATE_PAYMENT_FAIL,
  VALIDATE_PAYMENT_SUCCESS,
} from "./types";

const initialState: PaymentState = {
  fetching: false,
  fetchError: undefined,
  navigating: false,
};

export function paymentsReducer(
  state = initialState,
  action: PaymentActionTypes
): PaymentState {
  switch (action.type) {
    case FETCH_PAYMENT:
      return {
        ...state,
        fetching: true,
        fetchError: undefined,
      };
    case FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payload,
        fetching: false,
        fetchError: undefined,
      };
    case VALIDATE_PAYMENT:
      return {
        ...state,
        validated: undefined,
      };
    case VALIDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        validated: true,
      };
    case VALIDATE_PAYMENT_FAIL:
      return {
        ...state,
        validated: false,
      };
    case FETCH_PAYMENT_FAIL:
      return {
        ...state,
        fetchError: action.payload,
        fetching: false,
      };
    case UPDATE_PAYMENT:
      return {
        ...state,
        payment: {
          ...action.payload.payment,
          status: action.payload.status,
          data: action.payload.data as PaymentIntent | IOfflinePayment,
        },
      };
    case NAVIGATE_TO_PAYMENT:
      return {
        ...state,
        payment: undefined,
        navigating: true,
      };
    case NAVIGATE_TO_PAYMENT_SUCCESS:
      return {
        ...state,
        navigating: false,
      };

    default:
      return state;
  }
}
