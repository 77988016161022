import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { getUser, refreshAuthToken } from "../store/user/actions";

const UserInit = () => {
  const isInitailized = useSelector(
    (state: RootState) => state.settings.isInitialized
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isInitailized) dispatch(getUser());
  }, [dispatch, isInitailized]);

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && isInitailized) {
        dispatch(refreshAuthToken());
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [dispatch, isInitailized]);

  return null;
};

export default UserInit;
