import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const NotAuthorized = () => {
  const seekrDomain = useSelector((state: RootState) => state.settings.SEEKR_DOMAIN);
  return (
  <Box textAlign="center">
    <Typography variant="h4" color="error" gutterBottom>
      Not Authorized
    </Typography>
    <Typography gutterBottom>
      It looks like you need to login before you can view your credit.
    </Typography>
    <Button
      href={`${seekrDomain}/accounts/login/?next=${document.location.href}`}
      variant="contained"
      color="primary"
    >
      Login Now
    </Button>
  </Box>
)};
export default NotAuthorized;