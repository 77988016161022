import { useCallback } from "react";
import { useSelector } from "react-redux";
import {EVENT_NAME_PAYMENT_DECLINED} from "./constants";
import {trackAnalyticsEvent} from "./event";
import {
    getCurrentPaymentCartId,
    getPaymentPlatform,
    getPaymentRef
} from "../../store/payments/selectors";

export const useTrackPaymentDeclined = () => {
    const cartId = useSelector(getCurrentPaymentCartId);
    const platform = useSelector(getPaymentPlatform);
    const ref = useSelector(getPaymentRef);

    return useCallback((reason: string, cardBrand?: string) => {
        trackAnalyticsEvent(EVENT_NAME_PAYMENT_DECLINED, {
            reason,
            checkout_id: `checkout-${cartId}`,
            order_id: cartId,
            cart_id: cartId,
            platform,
            cardBrand,
            payment_ref: ref,
        });
    }, [cartId, platform, ref]);
};
