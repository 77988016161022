import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
} from "@material-ui/core";

const UnprocessablePaymentDialog = (props: DialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent>
        <Typography>
          There was an error processing your payment. Please go back and try
          again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            window.history.back()
          }
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnprocessablePaymentDialog;
