import React from "react";

import { IPaymentData, PaymentStatus } from "../../store/payments/types";
import { IUserData } from "../../store/user/types";
import NeedsReplacingDialog from "./NeedsReplacingDialog";
import Summary from "./Summary";
import Platform from "./platform";

export interface Props {
  payment: IPaymentData;
  user?: IUserData;
  onSuccess: (status: PaymentStatus) => void;
  onReload: () => void;
}

const Payment = ({ payment, user, onSuccess, onReload }: Props) => {
  return (
    <>
      <Summary payment={payment} />
      <Platform
        payment={payment}
        user={user}
        onSuccess={onSuccess}
        onReload={onReload}
      />
      {payment.needs_replacing && <NeedsReplacingDialog />}
    </>
  );
};

export default Payment;
