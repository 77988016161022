import React from "react";
import { Typography, Box, Button } from "@material-ui/core";

function FourOhFour() {
  return (
    <Box>
      <Typography variant="h3" component="h1" gutterBottom color="error">
        Much Better Payments (404!)
      </Typography>
      <Typography paragraph variant="body2">
        That URL doesn't exist! Please check the URL you've been given to view
        the payment you're interested in. Without that, there's nothing we can
        do!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="https://www.muchbetteradventures.com/"
      >
        Find an adventure!
      </Button>
    </Box>
  );
}

export default FourOhFour;
