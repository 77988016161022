import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

import {
  CreditOrAllocation,
  ICredit,
} from "../../store/accountStatement/types";
import theme from "../../theme/theme";
import { fmtMoney } from "../../utils";
import Credit from "./Credit";

const useStyles = makeStyles({
  table: {
    "& td": {
      verticalAlign: "top",
    },
  },
  head: {
    ...theme.typography.h5,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  total: {
    ...theme.typography.h4,
    border: "none",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  right: {
    textAlign: "right",
  },
});

export interface Props {
  credits: ICredit[];
}

const totalAmount = (credits: CreditOrAllocation[]) =>
  credits.reduce((total, { amount }) => total + amount, 0);

const totalRemaining = (credits: ICredit[]) =>
  credits.reduce(
    (total, { amount, allocations }) =>
      total + amount - totalAmount(allocations),
    0
  );

const AccountStatement = ({ credits }: Props) => {
  const classes = useStyles();
  return (
    <>
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>Description</TableCell>
              <TableCell className={classes.head}>Valid To</TableCell>
              <TableCell className={clsx(classes.head, classes.right)}>
                Amount
              </TableCell>
              <TableCell className={clsx(classes.head, classes.right)}>
                Balance
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {credits.map((credit) => (
              <Credit credit={credit} key={credit.id} />
            ))}
            <TableRow key="total">
              <TableCell colSpan={3} className={classes.total}>
                Balance Remaining
              </TableCell>
              <TableCell className={clsx(classes.total, classes.right)}>
                {fmtMoney([totalRemaining(credits), "gbp"])}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography color="textSecondary">
                  Please Note: Credit will be converted into your payment
                  currency (if different) at checkout.
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccountStatement;
