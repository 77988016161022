import React from "react";
import {
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { Box, Grid } from "@material-ui/core";
import countries from "../../../store/payments/countries";
import { BillingDetails } from "../../../store/user/types";

type FieldName =
  | "name"
  | "email"
  | "phone"
  | "address.line1"
  | "address.line2"
  | "address.city"
  | "address.state"
  | "address.country"
  | "address.postal_code";

interface BillingDetailsField {
  name: FieldName;
  label: string;
  width?: 4 | 6;
  validators?: string[];
  errorMessages?: string[];
  choices?: [string, string][];
  getValue: (billingDetails: BillingDetails) => string | undefined;
  setValue: (billingDetails: BillingDetails, value: string) => BillingDetails;
  autocomplete?: string;
}

type BillingDetailsFields = BillingDetailsField[];

const setAddressValue = (
  billingDetails: BillingDetails,
  addressFieldName: string,
  value: string
) => ({
  ...billingDetails,
  address: {
    ...billingDetails.address,
    [addressFieldName]: value,
  },
});

const fields: BillingDetailsFields = [
  {
    name: "name",
    label: "Full Name",
    autocomplete: "cc-name",
    validators: ["required"],
    getValue: (billingDetails) => billingDetails.name,
    setValue: (billingDetails, value) => ({
      ...billingDetails,
      name: value,
    }),
  },
  // {
  //   name: "phone",
  //   label: "Phone Number",
  //   width: 6,
  //   validators: ["required"],
  //   getValue: (billingDetails: BillingDetails) => billingDetails.phone,
  //   setValue: (billingDetails: BillingDetails, value: string) => ({
  //     ...billingDetails,
  //     phone: value,
  //   }),
  // },
  {
    name: "email",
    label: "Email Address",
    validators: ["required", "isEmail"],
    errorMessages: [
      "This field is required",
      "Please enter a valid email address",
    ],
    getValue: (billingDetails) => billingDetails.email,
    setValue: (billingDetails, value) => ({
      ...billingDetails,
      email: value,
    }),
  },

  {
    name: "address.line1",
    label: "House number and street name",
    autocomplete: "address-line1",
    validators: ["required"],
    getValue: (billingDetails) => billingDetails.address?.line1,
    setValue: (billingDetails, value) =>
      setAddressValue(billingDetails, "line1", value),
  },
  {
    name: "address.line2",
    label: "Additional address details (optional)",
    autocomplete: "address-line2",
    getValue: (billingDetails) => billingDetails.address?.line2,
    setValue: (billingDetails, value) =>
      setAddressValue(billingDetails, "line2", value),
  },
  {
    name: "address.city",
    label: "City",
    autocomplete: "address-level2",

    width: 6,
    validators: ["required"],
    getValue: (billingDetails) => billingDetails.address?.city,
    setValue: (billingDetails, value) =>
      setAddressValue(billingDetails, "city", value),
  },
  {
    name: "address.state",
    label: "County / State",
    autocomplete: "address-level1",
    width: 6,
    validators: ["required"],
    getValue: (billingDetails) => billingDetails.address?.state,
    setValue: (billingDetails, value) =>
      setAddressValue(billingDetails, "state", value),
  },
  {
    name: "address.country",
    label: "Country",
    autocomplete: "country",
    width: 6,
    validators: ["required"],
    choices: countries.map((country) => [country.code, country.name]),
    getValue: (billingDetails) => billingDetails.address?.country,
    setValue: (billingDetails, value) =>
      setAddressValue(billingDetails, "country", value),
  },
  {
    name: "address.postal_code",
    label: "Postal Code",
    autocomplete: "postal-code",
    width: 6,
    validators: ["required"],
    getValue: (billingDetails) => billingDetails.address?.postal_code,
    setValue: (billingDetails, value) =>
      setAddressValue(billingDetails, "postal_code", value),
  },
];

const defaultBillingDetails: BillingDetails = {
  name: "",
  email: "",
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "GB",
    postal_code: "",
  },
};

const BillingDetailsForm = ({
  handleChange,
  initial = {},
  disabled = false,
}: {
  handleChange?: Function;
  initial?: BillingDetails;
  disabled?: boolean;
}) => {
  const [billingDetails, setBillingDetails] = React.useState({
    ...defaultBillingDetails,
    ...initial,
    address: { ...defaultBillingDetails.address, ...initial.address },
  } as BillingDetails);

  const handleFieldChange = (field: BillingDetailsField) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value } = event.target;
    const nextBillingDetails = field.setValue(billingDetails, value);
    setBillingDetails(nextBillingDetails);
    handleChange && handleChange(nextBillingDetails);
  };

  return (
    <Box mt={2}>
      <Grid container spacing={1}>
        {fields.map((field) => (
          <Grid item key={field.name} xs={12} sm={field.width || 12}>
            {field.choices ? (
              <Box mb={1}>
                <SelectValidator
                  label={field.label}
                  onChange={handleFieldChange(field)}
                  name={field.name}
                  value={field.getValue(billingDetails)}
                  validators={field.validators}
                  variant="outlined"
                  fullWidth
                  autoComplete={field.autocomplete || "on"}
                  errorMessages={
                    field.errorMessages || ["this field is required"]
                  }
                  SelectProps={{
                    native: true,
                  }}
                  disabled={disabled}
                >
                  {field.choices.map((choice) => (
                    <option key={choice[0]} value={choice[0]}>
                      {choice[1]}
                    </option>
                  ))}
                </SelectValidator>{" "}
              </Box>
            ) : (
              <Box mb={1}>
                <TextValidator
                  label={field.label}
                  onChange={handleFieldChange(field)}
                  name={field.name}
                  value={field.getValue(billingDetails)}
                  validators={field.validators}
                  variant="outlined"
                  autoComplete={field.autocomplete || "on"}
                  fullWidth
                  errorMessages={
                    field.errorMessages || ["this field is required"]
                  }
                  disabled={disabled}
                />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BillingDetailsForm;
