import React from "react";
import PayButton, { Props as PayButtonProps } from "./PayButton";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../store";

const mapState = ({ payments }: RootState) => ({
  ...payments,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & PayButtonProps;

const PayButtonHOC = ({ payment, ...rest }: Props) => {
  return (
    <PayButton
      {...rest}
      amount={payment ? payment.amount - payment.credit_amount : undefined}
      currency={payment?.currency}
    />
  );
};

export default connector(PayButtonHOC);
