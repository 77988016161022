/* MUI Type styles for MBA
  Docs:
  https://material-ui.com/customization/themes/#typography

  Defaults:
  https://material-ui.com/customization/default-theme/?expend-path=$.typography
*/

import { createTheme } from "@material-ui/core/styles";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

const fontFamily = [
  "Rubik",
  "\"Helvetica Neue\"",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

const fontFamilyBold = fontFamily;

const fontFamilySerif = [
  "Domine",
  "Georgia",
  "Times New Roman",
  "Times",
  "serif",
].join(",");

const textBlack = "#2A2D2C";

const typography: TypographyOptions = {
  // Bootstrap sets this to 10, we need to leave it as 10
  // else things render out tiny. see:
  // https://material-ui.com/customization/themes/#typography-html-font-size
  htmlFontSize: 10,
  fontSize: 16,
  fontFamily,
  h1: {
    fontFamily,
    textTransform: "uppercase",
    fontSize: 48,
    lineHeight: "60px",
    fontWeight: 900,
    overflow: "hidden",
    [breakpoints.down("xs")]: {
      fontSize: 32,
      lineHeight: "36px",
      letterSpacing: 0,
    },
  },
  h2: {
    fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 48,
    letterSpacing: 1.5,
    lineHeight: "52px",
    [breakpoints.down("xs")]: {
      fontSize: 24,
      lineHeight: "28px",
      letterSpacing: 0,
    },
  },
  h3: {
    fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    letterSpacing: 0,
  },
  h4: {
    fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: "20px",
  },
  h5: {
    fontFamily,
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  h6: {
    fontFamily,
    textTransform: "uppercase",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: 0,
  },
  subtitle1: {
    fontSize: 20,
  },
  body1: {
    fontFamily,
    fontSize: 16,
    lineHeight: "24px",
  },
  body2: {
    fontFamily: fontFamilySerif,
    fontSize: 16,
    lineHeight: "24px",
  },
  overline: {
    fontFamily,
    letterSpacing: 0.5,
    lineHeight: 1.5,
    textTransform: "uppercase",
    fontSize: 14,
    [breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  caption: {
    fontSize: 12,
    [breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
};

export default typography;
export {
  textBlack, fontFamily, fontFamilyBold, fontFamilySerif,
};
