import { useCallback } from "react";
import { useSelector } from "react-redux";
import {EVENT_NAME_PAYMENT_ACCEPTED} from "./constants";
import {trackAnalyticsEvent} from "./event";
import {
    getCurrentPaymentCartId,
    getPaymentPlatform,
    getPaymentRef
} from "../../store/payments/selectors";

export const useTrackPaymentAccepted = () => {
    const cartId = useSelector(getCurrentPaymentCartId);
    const platform = useSelector(getPaymentPlatform);
    const ref = useSelector(getPaymentRef);
    

    return useCallback((paymentMode?: string) => {
        trackAnalyticsEvent(EVENT_NAME_PAYMENT_ACCEPTED, {
            checkout_id: `checkout-${cartId}`,
            order_id: cartId,
            cart_id: cartId,
            platform,
            payment_ref: ref,
        });
    }, [cartId, platform, ref]);
};
