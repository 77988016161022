import { AxiosError } from "axios";
import React from "react";
import { useSelector } from "react-redux";

import { Box, Button, Typography } from "@material-ui/core";

import { RootState } from "../../store";

const NotFound = ({ fetchError }: { fetchError?: AxiosError }) => {
  const seekrDomain = useSelector(
    (state: RootState) => state.settings.SEEKR_DOMAIN
  );

  const loginLink = `${seekrDomain}/accounts/login/?next=${encodeURI(
    document.location.href
  )}`;

  let title = "Error";
  let message = "Sorry, we could not find the payment.";
  let showLoginBtn = false;

  if (fetchError?.response?.status === 404) {
    title = "Payment not found";
  } else if (fetchError?.response?.status === 401) {
    title = "Unauthorized";
    message = "You need to log in to view this payment.";
    showLoginBtn = true;
  } else if (fetchError?.response?.status === 403) {
    title = "Forbidden";
    message = "Your account does not have permission to view this payment.";
  } else if (fetchError?.response?.status === 500) {
    title = "Internal Server Error";
    message = "An error occurred while fetching the payment.";
  }

  return (
    <Box textAlign="center">
      <Typography variant="h4" color="error" gutterBottom>
        {title}
      </Typography>
      <Typography>{message}</Typography>
      {showLoginBtn && (
        <Box mt={1}>
          <Button href={loginLink} variant="contained" color="primary">
            Log In
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default NotFound;
