export interface IAllocation {
  debit_id: number;
  amount: number;
  description: string;
  timestamp: string;
  debit_amount: number;
}

export interface ICredit {
  id: number;
  user: string;
  amount: number;
  balance: number;
  description: string;
  expiry_date: string;
  timestamp: string;
  allocations: IAllocation[];
}

export type CreditOrAllocation = ICredit | IAllocation;

export interface AccountStatementState {
  credits?: ICredit[];
  fetching: boolean;
}

export const GET_ACCOUNTSTATEMENT = "GET_ACCOUNTSTATEMENT";
export const GET_ACCOUNTSTATEMENT_SUCCESS = "GET_ACCOUNTSTATEMENT_SUCCESS";
export const GET_ACCOUNTSTATEMENT_FAIL = "GET_ACCOUNTSTATEMENT_FAIL";

export interface IGetAccountStatementAction {
  type: typeof GET_ACCOUNTSTATEMENT;
  payload: string;
}

export interface IGetAccountStatementSuccessAction {
  type: typeof GET_ACCOUNTSTATEMENT_SUCCESS;
  payload: ICredit[];
}

export interface IGetAccountStatementFailAction {
  type: typeof GET_ACCOUNTSTATEMENT_FAIL;
  payload: Error;
}

export type AccountStatementActionTypes =
  | IGetAccountStatementAction
  | IGetAccountStatementSuccessAction
  | IGetAccountStatementFailAction;
