import AccountStatementService from "./service";

import {
    GET_ACCOUNTSTATEMENT,
    GET_ACCOUNTSTATEMENT_SUCCESS,
    GET_ACCOUNTSTATEMENT_FAIL,
} from "./types";

export const getAccountStatement = () => async (dispatch: any) => {
    dispatch({ type: GET_ACCOUNTSTATEMENT });
    try {
        const asyncResp = await AccountStatementService.fetchAccountStatement();
        dispatch({ type: GET_ACCOUNTSTATEMENT_SUCCESS, payload: asyncResp });
    } catch (err) {
        dispatch({ type: GET_ACCOUNTSTATEMENT_FAIL, payload: err });
    }
};

