import { useEffect } from "react";

// Note:
// We might try to fire some events before all the necessary dependencies have loaded.
// In that case, we add the event to a stack,
// and then use an effect to clear the stack once the dependencies have loaded

export const trackAnalyticsEvent = (
  trackEvent: string,
  trackProps = {},
  callback?: Function
) => {
  const dependenciesHaveLoaded = !!window.analytics;
  if (!dependenciesHaveLoaded) {
    earlyEventStack.push(() =>
      trackAnalyticsEvent(trackEvent, trackProps, callback)
    );
    return;
  }

  try {
    if (window.analytics && trackEvent) {
      window.analytics.track(trackEvent, trackProps, {}, callback);
    } else if (callback) {
      callback();
    }
  } catch (err) {
    console.error(
      "An error occured whilst trying to track event: ",
      trackEvent,
      err
    );
  }
};

let earlyEventStack: Function[] = [];

export const useSendEarlyAnalyticsTrackingEvents = (
  isTrackingLoaded: boolean
) => {
  useEffect(() => {
    if (isTrackingLoaded) {
      earlyEventStack.forEach((sendEvent) => sendEvent());
      earlyEventStack = [];
    }
  }, [isTrackingLoaded]);
};
