import React from "react";

import { PaymentIntent } from "@stripe/stripe-js";

import {
  IGr4vyEmbedPayment,
  IOfflinePayment,
  IPaymentData,
  PaymentPlatform,
  PaymentStatus,
} from "../../../store/payments/types";
import { IUserData } from "../../../store/user/types";
import Gr4vyEmbed from "./Gr4vyEmbed";
import Offline from "./Offline";
import StripePaymentIntents from "./StripePaymentIntents";

type PlatformMap = {
  [key in PaymentPlatform]: JSX.Element;
};

interface Props {
  payment: IPaymentData;
  user?: IUserData;
  onSuccess: (status: PaymentStatus) => void;
  onReload: () => void;
}

const MAX_CONFIRMATION_AMOUNT = 100;

const Platform = ({ payment, user, onSuccess, onReload }: Props) => {
  const platformMap: PlatformMap = {
    gr4vy_embed: (
      <Gr4vyEmbed
        paymentData={{...payment.data ,status: payment.status} as IGr4vyEmbedPayment}
        defaultBillingDetails={user?.default_billing_details}
        onSuccess={onSuccess}
      />
    ),
    stripe_payment_intents: (
      <StripePaymentIntents
        paymentIntent={payment.data as PaymentIntent}
        defaultBillingDetails={user?.default_billing_details}
        onSuccess={onSuccess}
      />
    ),
    offline: (
      <Offline
        paymentData={payment.data as IOfflinePayment}
        canConfirm={
          payment.status === "requires_confirmation" &&
          payment.amount - payment.credit_amount <= MAX_CONFIRMATION_AMOUNT
        }
        onSuccess={onSuccess}
        onReload={onReload}
      />
    ),
  };
  return payment.platform && payment.data
    ? platformMap[payment.platform]
    : null;
};
export default Platform;