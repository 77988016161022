import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const mountEl = document.getElementById("root");
const root = ReactDOM.createRoot(mountEl as HTMLElement);
root.render(
  <React.StrictMode>
    <App dataSet={mountEl?.dataset} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
