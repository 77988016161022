import React from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { RouteComponentProps, Router, useLocation } from "@reach/router";

import AccountStatementComponent from "./components/AccountStatement";
import FourOhFourComponent from "./components/FourOhFour";
import HomeComponent from "./components/Home";
import PaymentComponent from "./components/Payment";
import { Props as PaymentComponentProps } from "./components/Payment";
import { RootState } from "./store";

const Payment: React.FC<RouteComponentProps> = (props) => {
  const paymentProps = props as PaymentComponentProps;
  return <PaymentComponent paymentId={paymentProps.paymentId} />;
};
const AccountStatement: React.FC<RouteComponentProps> = () => {
  return <AccountStatementComponent />;
};
const Home: React.FC<RouteComponentProps> = () => <HomeComponent />;
const FourOhFour: React.FC<RouteComponentProps> = () => <FourOhFourComponent />;

interface InitializingProps {
  message: string;
  isError?: boolean;
}
const Initializing = ({ message, isError }: InitializingProps) => {
  const seekrDomain = useSelector(
    (state: RootState) => state.settings.SEEKR_DOMAIN
  );

  return (
    <Box>
      {isError && (
        <Dialog open>
          <DialogTitle disableTypography>
            <Typography variant="h4">Uh oh!</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {message} If you have an account with us, please log in and try
              again, or reload the page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => document.location.reload()}>
              Reload The Page
            </Button>
            <Button
              variant="contained"
              color="primary"
              href={`${seekrDomain}/accounts/login/?next=${document.location.href}`}
            >
              Login & Try Again
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Typography align="center" variant="h4">
        {message}
      </Typography>
    </Box>
  );
};

const Layout = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user, fetchingUser, fetchingUserError } = useSelector(
    (state: RootState) => state.user
  );

  const content = () => {
    if (!user && fetchingUser) {
      return <Initializing message="Initializing..." />;
    }
    if (fetchingUserError) {
      return (
        <Initializing message="We could not find your user details." isError />
      );
    }

    return (
      <Router>
        <Home path="/" />
        <Payment path="/payments/:paymentId" />
        <AccountStatement path="/account-statement" />
        <FourOhFour default />
      </Router>
    );
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box marginY={5}>
          <Box m={4} textAlign="center">
            <img
              alt="Much Better Adventures"
              src="https://d9742cxh932vm.cloudfront.net/2cba190709f9f9da32ff206998669fd3/img/muchbetteradventures.svg"
              style={{ height: 32 }}
            />
          </Box>
          <Card>
            <CardContent>{content()}</CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default Layout;
