import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";

const Loading = () => (
  <Box textAlign="center">
    <Typography variant="h4" gutterBottom>
      Loading Payment...
    </Typography>
    <Box mt={2}>
      <CircularProgress />
    </Box>
  </Box>
);
export default Loading;