import { CurrencyCode } from "./currencyCode";
import { PaymentPlatform } from "./types";
import { RootState } from "..";

export const getPaymentRef = (state: RootState): string | undefined => {
  return state.payments.payment?.ref;
};

export const getCurrentPaymentCartId = (state: RootState) => {
  return state.payments.payment?.metadata?.trip_cart_id ?? "";
};

export const getPaymentIsValidated = (state: RootState) => {
  return state.payments?.validated;
};

export const getPaymentPlatform = (
  state: RootState
): PaymentPlatform | undefined => {
  return state.payments?.payment?.platform;
};

export const getPaymentAmount = (state: RootState): number | undefined => {
  return state.payments?.payment?.amount;
};

export const getPaymentCurrency = (
  state: RootState
): CurrencyCode | undefined => {
  return state.payments?.payment?.currency;
};
