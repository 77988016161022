import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { useScript } from "../useScript";

declare global {
  interface Window {
    analytics: any;
    consentManagerConfig: any;
  }
}

export const useLoadTracking = () => {
  const {
    TRACKING_SCRIPT_SRC,
    SEGMENT_API_KEY,
    POSTHOG_API_KEY,
    PLAUSIBLE_DOMAIN,
  } = useSelector((store: RootState) => store.settings);

  const isTrackingLoaded = useScript(
    "tracking-script",
    TRACKING_SCRIPT_SRC || "",
    {
      "data-segment-write-key": SEGMENT_API_KEY || "",
      "data-posthog-api-key": POSTHOG_API_KEY || "",
      "data-plausible-domain": PLAUSIBLE_DOMAIN || "",
    }
  );

  return isTrackingLoaded;
};
