import React from "react";
import { useSelector } from "react-redux";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { BillingDetails } from "../../../../store/user/types";
import { PaymentStatus } from "../../../../store/payments/types";
import { RootState } from "../../../../store";

interface Props {
  paymentIntent: PaymentIntent;
  defaultBillingDetails?: BillingDetails;
  onSuccess: (status: PaymentStatus) => void;
}

const StripePaymentIntents = ({ paymentIntent, onSuccess, defaultBillingDetails }: Props) => {
  const handleSuccess = (paymentIntent: PaymentIntent) => {
    onSuccess(paymentIntent.status);
  };

  const { STRIPE_PUBLIC_KEY, STRIPE_API_VERSION } = useSelector(
    (store: RootState) => store.settings
  );

  const [stripePromise, setStripePromise] = React.useState<
    Promise<Stripe | null>
  >();

  React.useEffect(() => {
    setStripePromise(
      loadStripe(STRIPE_PUBLIC_KEY, {
        apiVersion: STRIPE_API_VERSION,
      })
    );
  }, [STRIPE_PUBLIC_KEY, STRIPE_API_VERSION]);

  return paymentIntent.status === "requires_payment_method" && stripePromise ? (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        paymentIntent={paymentIntent}
        defaultBillingDetails={defaultBillingDetails}
        onSuccess={handleSuccess}
      />
    </Elements>
  ) : null;
};
export default StripePaymentIntents;