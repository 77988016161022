import { navigate } from "@reach/router";

import { AppThunk } from "../types";
import PaymentService from "./service";
import {
  FETCH_PAYMENT,
  FETCH_PAYMENT_FAIL,
  FETCH_PAYMENT_SUCCESS,
  IFetchPaymentFailAction,
  IFetchPaymentSuccessAction,
  INavigateToPaymentSuccessAction,
  IPaymentData,
  NAVIGATE_TO_PAYMENT,
  NAVIGATE_TO_PAYMENT_SUCCESS,
  PaymentStatus,
  UPDATE_PAYMENT,
  VALIDATE_PAYMENT,
  VALIDATE_PAYMENT_FAIL,
  VALIDATE_PAYMENT_SUCCESS,
} from "./types";

export const fetchPayment =
  (
    id: string
  ): AppThunk<Promise<IFetchPaymentSuccessAction | IFetchPaymentFailAction>> =>
  async (dispatch) => {
    dispatch({ type: FETCH_PAYMENT });
    try {
      const response = await PaymentService.fetchPayment(id);
      return dispatch({
        type: FETCH_PAYMENT_SUCCESS,
        payload: response,
      } as IFetchPaymentSuccessAction);
    } catch (error) {
      return dispatch({
        type: FETCH_PAYMENT_FAIL,
        payload: error,
      }) as IFetchPaymentFailAction;
    }
  };

export const navigateToPayment =
  (id: string): AppThunk<Promise<INavigateToPaymentSuccessAction>> =>
  async (dispatch) => {
    dispatch({ type: NAVIGATE_TO_PAYMENT, payload: id });
    await navigate(`/payments/${id}`);
    return dispatch({
      type: NAVIGATE_TO_PAYMENT_SUCCESS,
      payload: id,
    } as INavigateToPaymentSuccessAction);
  };

export const updatePayment = (
  payment: IPaymentData,
  status: PaymentStatus,
  data: object
) => ({
  type: UPDATE_PAYMENT,
  payload: {
    payment,
    status,
    data,
  },
});

export const validatePaymentDoesNotNeedReplacing =
  (): AppThunk<Promise<IFetchPaymentSuccessAction | undefined>> =>
  async (dispatch, getState) => {
    const currentPaymentId = getState().payments.payment?.id;

    if (!currentPaymentId) {
      return;
    }

    dispatch({ type: VALIDATE_PAYMENT });
    try {
      const response = await PaymentService.fetchPayment(currentPaymentId);
      if (response.needs_replacing) {
        dispatch({
          type: VALIDATE_PAYMENT_FAIL,
        });
      } else {
        dispatch({
          type: VALIDATE_PAYMENT_SUCCESS,
        });
      }
      return dispatch({
        type: FETCH_PAYMENT_SUCCESS,
        payload: response,
      } as IFetchPaymentSuccessAction);
    } catch (error) {
      dispatch({
        type: VALIDATE_PAYMENT_FAIL,
      });
      throw error;
    }
  };
